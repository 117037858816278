import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    isDisabled: true,
  },

  mutations: {

  },
  actions: {

  },
}
