export default [
    {
        path: '/admission/:institute',
        name: 'admission',
        component: () => import('@/views/pages/admission/OnlineAdmission.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },

    {
        path: '/admission/:institute/:formId',
        name: 'form',
        component: () => import('@/views/pages/admission/OnlineAdmissionForm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },

    {
        path: '/admission/:institute/:formId/temporaryId/:temporaryId',
        name: 'formDetails',
        component: () => import('@/views/pages/admission/OnlineAdmissionFormDetails.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },

    {
        path: '/admission/:institute/:formId/temporaryId/:temporaryId/attachment',
        name: 'attachment',
        component: () => import('@/views/pages/admission/OnlineAttachment.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },

    {
        path: '/admission/payment/:institute/online-payment',
        name: 'onlinePayment',
        component: () => import('@/views/pages/admission/payment/OnlinePayment.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },

    {
        path: '/payment/successful/:institute',
        name: 'paymentSuccessful',
        component: () => import('@/views/pages/admission/payment/Successful.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },

    {
        path: '/payment/failed/:institute',
        name: 'paymentSuccessful',
        component: () => import('@/views/pages/admission/payment/Failed.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },
]
