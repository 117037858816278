import Vue from 'vue'

// axios
import http from 'axios'

const axios = http.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://localhost:8002/api/v2/',
  // baseURL: process.env.VUE_APP_API_URL_V2,
  baseURL: 'https://api.ois.edu.bd/api/v2/',
  // apiURL: 'http://172.30.18.25/adil/core/public/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

const token = document.head.querySelector('meta[name="csrf-token"]')

if (!token) {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}
// if (token) {
//   axiosIns.headers['X-CSRF-TOKEN'] = token.content
// } else {
//   console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
// }

Vue.prototype.$http = axios

export default axios
