import axiosIns from '@/libs/axios'

export default {
  getIp: (() => 'http://ipinfo.io'),
  login: (loginPayload => axiosIns.post('auth/login', loginPayload)),
  logOut: (() => axiosIns.get('auth/logout')),
  userInfo: (() => axiosIns.get('auth/user')),
  // forgotUsername: (() => axiosIns.post('auth/forgot-username/send-username')),

  userInfoForce: (accessToken => axiosIns.get('auth/user', {
    headers: ({
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${accessToken || ''}`,
    }),
  })),
  refreshTokenGenerate: (() => axiosIns.get('auth/refresh-token-generate')),
  refreshLogin: (isRememberMe => axiosIns.post('auth/refresh-login', isRememberMe)),
  newUserPasswordReset: (passwordPayload => axiosIns.post('auth/new-user-password-reset', passwordPayload)),
  forgotPasswordReset: (passwordPayload => axiosIns.post('auth/forgot-password', passwordPayload)),
  forgotPasswordResetOTPVerify: (OTPPayload => axiosIns.post('auth/otp-verify', OTPPayload)),
  emailResendOTP: (OTPPayload => axiosIns.post('auth/email-resend-otp', OTPPayload)),
  phoneResendOTP: (OTPPayload => axiosIns.post('auth/mobile-resend-otp', OTPPayload)),
  changePassword: (data => axiosIns.post('auth/user-password-update', data)),
}
