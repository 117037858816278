export default {
    namespaced: true,
    state: {
        institute: null,
        temporaryId: null,
        temporaryId: null,
        applicantData: null,
        applicantDetailsData: null,
        singleFormData: null,
        applicantId: 4343,
    },
    getters: {
        getApplicantId: state => state.applicantId,
    },
    mutations: {
        setApplicantData(state, data) {
            console.log("mutations");
            state.applicantData = data;
        },
        setInstitute(state, data) {
            console.log("mutations");
            state.institute = data;
        },
        setTemporaryId(state, data) {
            console.log("mutations");
            state.temporaryId = data;
        },
        setSingleForm(state, data) {
            console.log("mutations");
            state.singleFormData = data;
        },
        setApplicantDetailsdata(state, data) {
            console.log("mutations");
            state.applicantDetailsdata = data;
        },
        setapplicantId(state, data) {
            console.log("mutations");
            state.applicantId = data;
        },

    },
    actions: {
        updateApplicantData({ commit }, data) {
            console.log("actions");
            commit("setApplicantData", data);
        },

        institute({ commit }, data) {
            console.log("actions");
            commit("setInstitute", data);
        },
        temporaryId({ commit }, data) {
            console.log("actions");
            commit("setTemporaryId", data);
        },

        singleForm({ commit }, data) {
            console.log("actions");
            commit("setSingleForm", data);
        },

        applicantDetailsdata({ commit }, data) {
            console.log("actions");
            commit("setApplicantDetailsdata", data);
        },

        applicantId({ commit }, data) {
            console.log("actions");
            commit("setapplicantId", data);
        },
        
    },
}
