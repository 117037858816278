import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  coas: [
    // {
    //   id: 1,
    //   fullName: 'Galen Slixby',
    //   company: 'Yotz PVT LTD',
    //   role: 'editor',
    //   username: 'gslixby0',
    //   country: 'El Salvador',
    //   contact: '(479) 232-9151',
    //   email: 'gslixby0@abc.net.au',
    //   currentPlan: 'enterprise',
    //   status: 'inactive',
    //   avatar: '',
    // },
    // {
    //   id: 2,
    //   fullName: 'Halsey Redmore',
    //   company: 'Skinder PVT LTD',
    //   role: 'author',
    //   username: 'hredmore1',
    //   country: 'Albania',
    //   contact: '(472) 607-9137',
    //   email: 'hredmore1@imgur.com',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/10.png'),
    // },
    {
      id: 3,
      accountType: {
        id: 1,
        name: 'Asset',
      },
      mainHead: {
        id: 1,
        name: 'Current Asset',
      },
      subHead: {
        id: 1,
        name: 'Cash & Cash Equivalent',
      },
      childHead: {
        id: 1,
        name: 'Cash & Cash Equivalent',
      },
      subChildHead: {
        id: 1,
        name: 'Head Office Cash',
      },
      glCode: '1010101001',
      fullName: 'Halsey Redmore',
      company: 'Skinder PVT LTD',
      role: 'author',
      username: 'hredmore1',
      country: 'Albania',
      contact: '(472) 607-9137',
      email: 'hredmore1@imgur.com',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@/assets/images/avatars/10.png'),
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/accounting/coa/coas').reply(config => {
// mock.onGet('/apps/user/users').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.coas.filter(
    coa =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (coa.username.toLowerCase().includes(queryLowered) || coa.fullName.toLowerCase().includes(queryLowered)) &&
      coa.role === (role || coa.role) &&
      coa.currentPlan === (plan || coa.currentPlan) &&
      coa.status === (status || coa.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      coas: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/user/users').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
