import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import onlineAdmission from './online-admission'
import forgotUsername from '@/store/auth/forgot-username'
import forgotPassword from '@/store/auth/forgot-password'
import button from '@/store/common/button'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // reducer: state => ({
  //   forgotPassword: [state.username, state.selectIdentity],
  //   // keepThisModuleToo: state.keepThisModuleToo
  //   // getRidOfThisModule: state.getRidOfThisModule (No one likes it.)
  // }),
})

export default new Vuex.Store({
  modules: {
    onlineAdmission,
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    'forgot-username': forgotUsername,
    'forgot-password': forgotPassword,
    button,
  },
  strict: process.env.DEV,
  plugins: [vuexLocalStorage.plugin],
})
