export default [
  {
    path: '/coa',
    name: 'coa',
    component: () => import('@/views/coa/CoaList.vue'),
    // component: () => import('@/views/coa/coa-list/CoaList.vue'),
    meta: {
      action: 'coa',
      resource: 'coa',
    },
  },
  {
    path: '/coa-mapping',
    name: 'coa-mapping',
    component: () => import('@/views/coa/CoaMapping.vue'),
    // component: () => import('@/views/coa/coa-list/CoaList.vue'),
    meta: {
      action: 'coa-mapping',
      resource: 'coa-mapping',
    },
  },
  // {
  //   path: '/coa-old',
  //   name: 'coa-old',
  //   component: () => import('@/views/coa/coa-list/CoaList.vue'),
  // },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () => import('@/views/voucher/Voucher.vue'),
    meta: {
      action: 'getVouchers',
      resource: 'vouchers',
    },
  },
  {
    path: '/coa-config',
    name: 'coa-config',
    component: () => import('@/views/coa/COAConfig.vue'),
    meta: {
      action: 'coa-config',
      resource: 'coa-config',
    },
  },
  {
    path: '/std-fee-config',
    name: 'std-fee-config',
    component: () => import('@/views/fee-configs/StdFeeConfig.vue'),
    meta: {
      action: 'std-fee-config',
      resource: 'std-fee-config',
    },
  },
  {
    path: '/payment-cycle-config',
    name: 'payment-cycle-config',
    component: () => import('@/views/payment-cycle-configs/PaymentCycleConfig.vue'),
    meta: {
      action: 'payment-cycle-config',
      resource: 'payment-cycle-config',
    },
  },
  {
    path: '/franchise-revenue',
    name: 'franchise-revenue',
    component: () => import('@/views/franchise-revenue/FranchiseRevenue.vue'),
    meta: {
      action: 'franchise-revenue',
      resource: 'franchise-revenue',
    },
  },
  {
    path: '/vat-config',
    name: 'vat-config',
    component: () => import('@/views/vat-config/VatConfig.vue'),
    meta: {
      action: 'vat-config',
      resource: 'vat-config',
    },
  },
  {
    path: '/fintech-service',
    name: 'fintech-service',
    component: () => import('@/views/fintech/FintechService.vue'),
    meta: {
      action: 'fintech-service',
      resource: 'fintech-service',
    },
  },
  {
    path: '/fintech',
    name: 'fintech',
    component: () => import('@/views/fintech/Fintech.vue'),
    meta: {
      action: 'fintech',
      resource: 'fintech',
    },
  },
  {
    path: '/fintech-service-provider',
    name: 'fintech-service-provider',
    component: () => import('@/views/fintech/FintechServiceProvider.vue'),
    meta: {
      action: 'fintech-service-provider',
      resource: 'fintech-service-provider',
    },
  },
  {
    path: '/merchant-settlement-account',
    name: 'merchant-settlement-account',
    component: () => import('@/views/fintech/MerchantSettlementAccount.vue'),
    meta: {
      action: 'merchant-settlement-account',
      resource: 'merchant-settlement-account',
    },
  },
  {
    path: '/fintech-institute-service',
    name: 'fintech-institute-service',
    component: () => import('@/views/fintech/FintechInstituteService.vue'),
    meta: {
      action: 'fintech-institute-service',
      resource: 'fintech-institute-service',
    },
  },
  {
    path: '/institute-campus-merchant-service',
    name: 'institute-campus-merchant-service',
    component: () => import('@/views/fintech/InstituteCampusMerchantService.vue'),
    meta: {
      action: 'ins-fintech',
      resource: 'ins-fintech',
    },
  },
  {
    path: '/fintech-merchant',
    name: 'fintech-merchant',
    component: () => import('@/views/fintech/FintechMerchant.vue'),
    meta: {
      action: 'fintech-merchant',
      resource: 'fintech-merchant',
    },
  },
  {
    path: '/payment-collection',
    name: 'payment-collection',
    component: () => import('@/views/payment-collection/PaymentCollection.vue'),
    meta: {
      action: 'payment-collection',
      resource: 'payment-collection',
    },
  },
  {
    path: '/group-invoice',
    name: 'group-invoice',
    component: () => import('@/views/group-invoice/GroupInvoice.vue'),
    meta: {
      action: 'group-invoice',
      resource: 'group-invoice',
    },
  },
  {
    path: '/paid-report',
    name: 'paid-report',
    component: () => import('@/views/reports/PaidReport.vue'),
    meta: {
      action: 'paid-report',
      resource: 'paid-report',
    },
  },
  {
    path: '/due-report',
    name: 'due-report',
    component: () => import('@/views/reports/DueReport.vue'),
    meta: {
      action: 'due-report',
      resource: 'due-report',
    },
  },
  {
    path: '/student-ledger',
    name: 'student-ledger',
    component: () => import('@/views/student-ledger/StudentLedger.vue'),
    meta: {
      action: 'student-ledger',
      resource: 'student-ledger',
    },
  },
  {
    path: '/head-wise-ledger',
    name: 'head-wise-ledger',
    component: () => import('@/views/head-wise-ledger/HeadWiseLedger.vue'),
    meta: {
      action: 'head-wise-ledger',
      resource: 'head-wise-ledger',
    },
  },
  {
    path: '/head-wise-summaries',
    name: 'head-wise-summaries',
    component: () => import('@/views/head-wise-summaries/HeadWiseSummaries.vue'),
    meta: {
      action: 'head-wise-summaries',
      resource: 'head-wise-summaries',
    },
  },
  {
    path: '/advance-override',
    name: 'advance-override',
    component: () => import('@/views/advance-overrides/AdvanceOverrides.vue'),
    meta: {
      action: 'advance-override',
      resource: 'advance-override',
    },
  },
  {
    path: '/invoice-print-settings',
    name: 'invoice-print-settings',
    component: () => import('@/views/invoice-print-settings/InvoicePrintSetting.vue'),
    meta: {
      action: 'invoice-print-settings',
      resource: 'invoice-print-settings',
    },
  },
  {
    path: '/salary-disbursements',
    name: 'salary-disbursements',
    component: () => import('@/views/salary-disbursement/SalaryDisbursement.vue'),
    meta: {
      action: 'salary-disbursements',
      resource: 'salary-disbursements',
    },
  },
  {
    path: '/config-fine-management',
    name: 'config-fine-management',
    component: () => import('@/views/config-fine-management/ConfigFineManagement.vue'),
    meta: {
      action: 'config-fine-management',
      resource: 'config-fine-management',
    },
  },
  {
    path: '/student-concessions',
    name: 'student-concessions',
    component: () => import('@/views/student-concessions/StudentConcessions.vue'),
    meta: {
      action: 'student-concessions',
      resource: 'student-concessions',
    },
  },
  {
    path: '/sms-notification',
    name: 'sms-notification',
    component: () => import('@/views/sms-notification/SmsNotification.vue'),
    meta: {
      action: 'sms-notification',
      resource: 'sms-notification',
    },
  },
  // {
  //   title: 'Fintech',
  //   route: null,
  //   children: [
  //     {
  //       path: '/vat-config',
  //       name: 'vat-config',
  //       component: () => import('@/views/vat-config/VatConfig.vue'),
  //     },
  //   ],
  // },
]
