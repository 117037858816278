export default [
  {
    path: '/config-resource-share-add-category',
    name: 'config-resource-share-add-category',
    component: () => import('@/views/resource-sharing/CategoryNames.vue'),
    meta: {
      action: 'customResource',
      resource: 'customResource',
    },
  },
  {
    path: '/config-resource-share-document-upload',
    name: 'config-resource-share-document-upload',
    component: () => import('@/views/resource-sharing/DocumentUpload.vue'),
    meta: {
      action: 'resourceSharing',
      resource: 'resourceSharing',
    },
  },
  {
    path: '/config-resource-share-permission-settings',
    name: 'config-resource-share-permission-settings',
    component: () => import('@/views/resource-sharing/PermissionSetttings.vue'),
    meta: {
      action: 'customResource',
      resource: 'customResource',
    },
  },
  {
    path: '/config-resource-share-document-type',
    name: 'config-resource-share-document-type',
    component: () => import('@/views/resource-sharing/DocumentType.vue'),
    meta: {
      action: 'customResource',
      resource: 'customResource',
    },
  },
  {
    path: '/report-resource-share-document-list',
    name: 'report-resource-share-document-list',
    component: () => import('@/views/resource-sharing/DocumentPrint.vue'),
    meta: {
      action: 'customResource',
      resource: 'customResource',
    },
  },
]
