export default [
  {
    path: '/booklist-syllabus-lp-migration',
    name: 'booklist-syllabus-lp-migration',
    component: () => import('@/views/migrations/BooklistSyllabusLpMigration.vue'),
    meta: {
      action: 'migration',
      resource: 'migration',
    },
  },
]
