export default [
  // {
  //   path: '/coa',
  //   name: 'coa',
  //   component: () => import('@/views/coa/coa-list/CoaList.vue'),
  //   // component: () => import('@/views/coa/List.vue'),
  //   // component: () => import('@/views/table/vue-good-table/GoodTable.vue'),
  // },
  // {
  //   path: '/add-coa',
  //   name: 'add-coa',
  //   component: () => import('@/views/accounting/Create.vue'),
  //   meta: {
  //     breadcrumb: [
  //       { title: 'Home', url: '/' },
  //       { title: 'Accounting' },
  //       { title: 'Add COA', active: true },
  //     ],
  //     pageTitle: 'Add Chart of Account',
  //     rule: 'admin',
  //   },
  // },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/:accessToken?',
    name: 'dashboard',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: {
      action: 'dashboard',
      resource: 'dashboard',
    },
  },
  {
    path: '/audits',
    name: 'audits',
    component: () => import('@/views/audit/Audits.vue'),
    // meta: {
    //   action: 'audit',
    //   resource: 'audit',
    // },
  },
  {
    path: '/oisacl-roles',
    name: 'oisacl-roles',
    component: () => import('@/views/oisacl-roles/OisaclRoles.vue'),
    meta: {
      action: 'oisacl-roles',
      resource: 'oisacl-roles',
    },
  },
  // {
  //   path: '/config-resource-share-add-category',
  //   name: 'configResourceShareAddCategory',
  //   component: () => import('@/views/resource-sharing/CategoryNames.vue'),
  // },
  // {
  //   path: '/config-resource-share-file-upload',
  //   name: 'configResourceShareFileUpload',
  //   component: () => import('@/views/resource-sharing/FileUpload.vue'),
  // },
  // {
  //   path: '/config-resource-share-permission-settings',
  //   name: 'configResourceSharePermissionSettings',
  //   component: () => import('@/views/resource-sharing/PermissionSetttings.vue'),
  // },
]
