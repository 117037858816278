import VueJwtDecode from 'vue-jwt-decode'
import { Ability } from '@casl/ability'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

import { initialAbility } from '@/libs/acl/config'

export const tokenType = 'Bearer'

export const AuthHeader = () => {
  const accessToken = LocalStorageService.get('accessToken')
  const refreshToken = LocalStorageService.get('refreshToken')
  // const accessTokenExp = LocalStorageService.get('accessTokenExp')
  const decodeToken = VueJwtDecode.decode(refreshToken)

  if (!accessToken || !refreshToken || new Date(decodeToken.exp * 1000) < new Date().getTime()) {
    LocalStorageService.clear()
    window.location.href = '/login'
    Ability(initialAbility)
    window.location.reload()
    return { name: 'auth-login' }
  }

  return {
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${accessToken || ''}`,
  }
}
