import { Ability } from '@casl/ability'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import { AuthHeader } from '@/@config/Header'
import AuthService from '@/@service/api/Auth.service'

import { initialAbility } from '@/libs/acl/config'
import VueJwtDecode from 'vue-jwt-decode'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = LocalStorageService.get('accessToken')

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers = AuthHeader()
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          const newToken = LocalStorageService.get('accessToken')
          if (!newToken) this.onLogout()
          const decodeToken = VueJwtDecode.decode(newToken)

          // Already token exists
          if (new Date(decodeToken.exp * 1000) > new Date().getTime()) {
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(() => {
                resolve(this.axiosIns(originalRequest))
              })
            })
            return retryOriginalRequest
          }

          // New refresh token generate
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.onRequestLogin()
            this.isAlreadyFetchingAccessToken = false
          }

          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }

        if (process.env.VUE_APP_ENV === 'production' && response.status >= 500) {
          this.onMaintenanceHandler()
        } else if (process.env.VUE_APP_ENV === 'production') {
          this.onConnectionErrorHandle()
        }

        return Promise.reject(error)
      },
    )
  }

  onRequestRefreshToken(accessToken) {
    AuthService.refreshTokenGenerate().then(
      response => {
        const res = response.data
        if (res.status) {
          this.setRefreshToken(res.refresh_token)
          this.onAccessTokenFetched(accessToken)
        } else {
          this.onLogout()
        }
      },
    ).catch(
      () => {
        this.onLogout()
      },
    )
  }

  // eslint-disable-next-line class-methods-use-this
  onRequestLogin() {
    LocalStorageService.delete('accessToken')
    const refreshToken = LocalStorageService.get('refreshToken')
    if (!refreshToken) return
    LocalStorageService.set('accessToken', refreshToken)
    const isRememberMe = LocalStorageService.get('isRememberMe')
    AuthService.refreshLogin({ remember_me: isRememberMe }).then(
      response => {
        const res = response.data
        LocalStorageService.delete('accessToken')
        if (res.status) {
          // Update accessToken in localStorage
          this.setToken(res.access_token)
          this.onRequestRefreshToken(res.access_token)
        } else {
          this.onLogout()
        }
      },
    ).catch(
      () => {
        this.onLogout()
      },
    )
  }

  // eslint-disable-next-line class-methods-use-this
  onLogout() {
    LocalStorageService.clear()
    window.location.href = '/login'
    Ability(initialAbility)
    window.location.reload()
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  onMaintenanceHandler=() => {
    window.location.href = '/miscellaneous/under-maintenance'
    this.route.push({ name: 'misc-under-maintenance' })
  }

  onConnectionErrorHandle=() => {
    window.location.href = '/miscellaneous/connection-error'
    this.route.push({ name: 'misc-connection-error' })
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
