import axiosIns from '@/libs/axiosV2'
import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    username: '',
    selectIdentity: {
      email: '',
      phone: '',
    },
    notifyType: '',
    verifyOtp: {
      otpCode: '',
    },

    passwordReset: {
      password: '',
      confirmPassword: '',
    },

  },

  mutations: {
    setUsername(state, username) {
      state.username = username
    },

    setNotifyType(state, notifyType) {
      state.notifyType = notifyType
    },

    setOtpCode(state, otpCode) {
      state.verifyOtp.otpCode = otpCode
    },

    setEmail(state, email) {
      state.selectIdentity.email = email
    },
    setPhone(state, phone) {
      state.selectIdentity.phone = phone
    },

    setPassword(state, password) {
      state.passwordReset.password = password
    },

    setConfirmPassword(state, password) {
      state.passwordReset.confirmPassword = password
    },
  },
  actions: {
    async submitUsername({ state, commit }) {
      try {
        await axiosIns.post('auth/forgot/get-email-and-phone', {
          user_name: state.username,
        })
          .then(({ data }) => {
            if (data.status === true) {
              console.log(data.data.email)
              if (data.data.email !== null && data.data.phone !== null) {
                commit('setEmail', data.data.email)
                commit('setPhone', data.data.phone)
                commit('setUsername', data.data.user_name)
                router.push({ name: 'auth-recover-identity' })
              } else {
                commit('setUsername', data.data.user_username)
                router.push({ name: 'otp-verify' })
                if (data.data.email !== null) {
                  Vue.$toast('An otp was sent to your email')
                } else {
                  Vue.$toast('An otp was sent to your mobile')
                }
              }
            }
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              Vue.$toast.error(response.data.message)
            }
          })
      } catch (loginError) {
        console.error(loginError)
      }
    },

    async sentOtp({ state, commit }) {
      try {
        await axiosIns.post('auth/forgot/otp-send', {
          user_name: state.username,
          notify_type: state.notifyType,
        })
          .then(({ data }) => {
            if (data.status === true) {
              commit('setUsername', data.data.user_name)

              router.push({ name: 'otp-verify' })
              Vue.$toast('An otp was sent to your device')
            }
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              Vue.$toast.error(response.data.message)
            }
          })
      } catch (loginError) {
        console.error(loginError)
      }
    },

    async submitOtp({ state, commit }) {
      try {
        await axiosIns.post('auth/forgot/verify-otp', {
          user_name: state.username,
          otp: state.verifyOtp.otpCode,
        })
          .then(({ data }) => {
            if (data.status === true) {
              commit('setUsername', state.username)
              router.push({ name: 'password.reset' })
              Vue.$toast('Congrats Otp match')
            }
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              Vue.$toast.error(response.data.message)
            }
          })
      } catch (loginError) {
        console.error(loginError)
      }
    },

    async submitPasswordReset({ state, commit }) {
      try {
        await axiosIns.post('auth/forgot/password-reset', {
          user_name: state.username,
          password: state.passwordReset.password,
          password_confirmation: state.passwordReset.confirmPassword,
        })
          .then(({ data }) => {
            if (data.status === true) {
              commit('setUsername', '')
              commit('setEmail', '')
              commit('setNotifyType', '')
              commit('setPhone', '')
              commit('setPassword', '')
              commit('setConfirmPassword', '')
              commit('setOtpCode', '')
              router.push({ name: 'auth-login' })
              Vue.$toast('Congrats your password reset successfully')
            }
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              Vue.$toast.error(response.data.message)
            }
          })
      } catch (loginError) {
        console.error(loginError)
      }
    },
  },
}
