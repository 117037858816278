import axiosIns from '@/libs/axiosV2'
import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    emailOrMobile: null,
    isDisabled: true,
  },
  getters: {},
  mutations: {
    setEmailMobile(state, emailOrMobile) {
      state.emailOrMobile = emailOrMobile
    },

    isAllowToSubmitForm(state) {
      if (state.emailOrMobile !== null) {
        state.isDisabled = false
      }
    },
  },
  actions: {
    async submitForgotUsername({ state, commit }) {
      try {
        axiosIns.post('auth/forgot-username/send-username ', {
          email_or_phone: state.emailOrMobile,
        })
          .then(({ data }) => {
            if (data.status === true) {
              if (data.data.hasOwnProperty('phone')) {
                Vue.$toast('Your username is sent on your mobile')
              }
              if (data.data.hasOwnProperty('email')) {
                Vue.$toast('Your username is sent on your email')
              }

              commit('setEmailMobile', '')
              router.push({ name: 'auth-login' })
            }
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              Vue.$toast.error(response.data.message)
            }
          })
      } catch (loginError) {
        console.error(loginError)
      }
    },
  },
}
